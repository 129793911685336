.hero {
  @apply container relative flex min-h-[22rem] flex-col items-center justify-center p-8 print:hidden;

  /* The card background uses css custom properties that are set inline in the component. */
  /* stylelint-disable-line comment-empty-line-before */ /* prettier-ignore */
  background: linear-gradient(var(--image-tint-color), var(--image-tint-color)), var(--image-fallback-color);

  &.alignFull {
    @apply align-full [max-width:unset];
  }

  &.alignWide {
    @apply align-wide [max-width:unset];
  }

  &.alignLeft,
  &.alignRight {
    @apply w-1/2;
  }

  &.alignLeft {
    @apply ml-0;
  }

  &.alignRight {
    @apply mr-0;
  }

  &.contentAlignTop {
    @apply justify-start;
  }

  &.contentAlignBottom {
    @apply justify-end;
  }

  &.contentAlignLeft {
    @apply items-start;
  }

  &.contentAlignRight {
    @apply items-end;
  }

  &.contentAlignLeft,
  &.contentAlignRight {
    & .content {
      @apply w-auto;
    }
  }

  &.fixed {
    @apply bg-fixed;
  }

  &.fullHeight {
    @apply min-h-[70vh] lg:max-h-screen lg:min-h-screen;
  }

  &.repeat {
    @apply bg-auto bg-repeat;
  }

  & .content {
    @apply relative z-10 w-full;

    &.whiteText {
      @apply text-white;
    }

    &.blackText {
      @apply text-black;
    }

    & .subtitle {
      @apply mb-2 text-sm opacity-80;
    }

    & .title {
      @apply mb-4 text-xl font-bold;
    }

    & .body + .button {
      @apply mt-16;
    }
  }

  & .overlay {
    @apply absolute bottom-0 left-0 right-0 top-0 z-[5] [background-color:inherit];

    &.overlayOnly {
      @apply z-auto;
    }
  }

  & .filterImage {
    @apply absolute inset-0 m-auto box-border block h-0 max-h-full min-h-full w-0 min-w-full max-w-full object-cover p-0;

    border: medium none;
  }

  & video {
    @apply absolute bottom-0 left-0 right-0 top-0 h-full w-full object-cover;
  }

  & > p {
    @apply mt-4;
  }
}

.filter {
  @apply invisible absolute -left-[9999px] overflow-hidden;
}

.link {
  @apply absolute inset-0 z-[13] lg:z-[11];
}
