.auctionCTA {
  @apply my-16 empty:my-0;

  & .logo {
    @apply max-w-[450px];
  }

  & .content {
    @apply my-2 text-center font-primary uppercase text-white;
  }

  & .info,
  & .date {
    @apply m-0 tracking-wider;
  }

  & .date {
    @apply text-2xl font-bold;
  }

  & .ctas {
    @apply mb-0;

    & .auctionLink {
      @apply xs:hidden;
    }
  }
}
