.buttonGroup {
  @apply my-8 flex gap-6;

  &.wrap {
    @apply flex-wrap;
  }

  &.nowrap {
    @apply flex-nowrap;
  }

  &.horizontal {
    &.center {
      @apply justify-center;
    }

    &.left {
      @apply justify-start;
    }

    &.right {
      @apply justify-end;
    }

    &.space-between {
      @apply justify-between;
    }

    &.verticalCenter {
      @apply items-center;
    }

    &.verticalTop {
      @apply items-start;
    }

    &.verticalBottom {
      @apply items-end;
    }
  }

  &.vertical {
    @apply flex-col;

    &.center {
      @apply items-center;
    }

    &.left {
      @apply items-start;
    }

    &.right {
      @apply items-end;
    }
  }
}
